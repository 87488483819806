.DeviceBox {
  width: 220px;
  height: 280px;
  background-color: #eee;
  margin: 8px;
  display: inline-block;
  position: relative;
}

.DeviceBox .DeviceConnectionStatusIcon {
  width: 20px;
  height: 20px;
  float: left;
  margin-top: 2px;
  margin-left: 4px;
}

.DeviceBox .id {
  float: left;
  color: #222;
  margin-top: 4px;
  margin-left: 4px;
  font-size: 15px;
  font-weight: bold;
}

.DeviceBox .HotspotIcon {
  float: left;
  color: #999;
  margin-top: 2px;
  margin-left: 4px;
  font-size: 16px;
}

.DeviceBox .password {
  float: left;
  clear: both;
  color: #222;
  cursor: pointer;
  margin-left: 28px;
  font-size: 12px;
}

.DeviceBox .watchers {
  color: #222;
  font-size: 14px;
  position: absolute;
  bottom: 4px;
  right: 8px;
}

.DeviceBox .pictures-count {
  color: #222;
  font-size: 14px;
  position: absolute;
  bottom: 4px;
  left: 8px;
}

.DeviceBox .picture-box {
  height: 200px;
  cursor: pointer;
  border: 1px dashed #aaa;
  margin: 52px 8px 8px;
  position: relative;
}

.DeviceBox .picture-box .image {
  width: 100%;
  height: 100%;
}

.DeviceBox .picture-box .no-image {
  width: 100%;
  opacity: .04;
  font-size: 130px;
}

.DeviceBox .picture-box .image-size {
  color: #999;
  font-size: 11px;
  position: absolute;
  top: 2px;
  right: 4px;
}

.DeviceBox .picture-box .quality {
  color: #999;
  font-size: 11px;
  position: absolute;
  top: 2px;
  left: 4px;
}

.DeviceBox .picture-box .time {
  color: #999;
  font-size: 11px;
  position: absolute;
  bottom: 0;
  left: 4px;
}

.DeviceBox .picture-box .refresh-btn {
  color: #bbb;
  font-size: 24px;
  position: absolute;
  bottom: 0;
  right: 8px;
}

.DeviceBox .picture-box .refresh-btn:hover {
  color: #fff;
}

.DeviceBox .picture-box:hover .time, .DeviceBox .picture-box:hover .quality, .DeviceBox .picture-box:hover .image-size {
  color: #fff;
  background: #222;
  padding: 2px 6px;
}

@media screen and (max-width: 660px) {
  .DeviceBox {
    width: 96%;
    height: 420px;
    margin: 8px auto;
  }

  .DeviceBox .picture-box {
    height: 340px;
  }

  .DeviceBox .picture-box .no-image {
    width: 100%;
    opacity: .04;
    font-size: 220px;
  }
}

/*# sourceMappingURL=index.bd4e5ece.css.map */
