.DeviceBox {
    width: 220px;
    height: 280px;
    background-color: #eee;
    position: relative;
    display: inline-block;
    margin: 8px;
    /* z-index: 20; */
}

.DeviceBox .DeviceConnectionStatusIcon {
    width: 20px;
    height: 20px;
    float: left;
    margin-left: 4px;
    margin-top: 2px;
}

.DeviceBox .id {
    float: left;
    margin-left: 4px;
    margin-top: 4px;
    font-size: 15px;
    color: #222;
    font-weight: bold;
}

.DeviceBox .HotspotIcon {
    float: left;
    margin-left: 4px;
    margin-top: 2px;
    font-size: 16px;
    color: #999;
}

.DeviceBox .password {
    float: left;
    clear: both;
    margin-left: 28px;
    font-size: 12px;
    color: #222;
    cursor: pointer;
}

.DeviceBox .watchers {
    position: absolute;
    right: 8px;
    bottom: 4px;
    font-size: 14px;
    color: #222;
}

.DeviceBox .pictures-count {
    position: absolute;
    left: 8px;
    bottom: 4px;
    font-size: 14px;
    color: #222;
}

.DeviceBox .picture-box {
    position: relative;
    margin: 8px;
    margin-top: 52px;
    height: 200px;
    border: 1px dashed #aaa;
    cursor: pointer;
}

.DeviceBox .picture-box .image {
    width: 100%;
    height: 100%;
}

.DeviceBox .picture-box .no-image {
    width: 100%;
    font-size: 130;
    opacity: 0.04;
}

.DeviceBox .picture-box .image-size {
    position: absolute;
    top: 2px;
    right: 4px;
    color: #999;
    font-size: 11px;
}

.DeviceBox .picture-box .quality {
    position: absolute;
    left: 4px;
    top: 2px;
    color: #999;
    font-size: 11px;
}

.DeviceBox .picture-box .time {
    position: absolute;
    left: 4px;
    bottom: 0;
    color: #999;
    font-size: 11px;
}

.DeviceBox .picture-box .refresh-btn {
    position: absolute;
    right: 8px;
    bottom: 0;
    font-size: 24px;
    color: #bbb;
}

.DeviceBox .picture-box .refresh-btn:hover {
    color: #fff;
}

.DeviceBox .picture-box:hover .time {
    color: #fff;
    background: #222;
    padding: 2px 6px;
}

.DeviceBox .picture-box:hover .quality {
    color: #fff;
    background: #222;
    padding: 2px 6px;
}

.DeviceBox .picture-box:hover .image-size {
    color: #fff;
    background: #222;
    padding: 2px 6px;
}


@media screen and (max-width: 660px) {
    .DeviceBox {
        width: 96%;
        margin: 8px auto;
        height: 420px;
    }

    .DeviceBox .picture-box {
        height: 340px;
    }

    .DeviceBox .picture-box .no-image {
        width: 100%;
        font-size: 220;
        opacity: 0.04;
    }
}